<template>
  <div>
    <section class="gs1-background-color-orange">
      <div class="container">
        <div class="row">
          <div class="col-12 gs1-navigator-spacer">
            <h2 class="gs1-h2 mt-0 mb-2 text-white">GPC Browser</h2>
            <p class="fs-6 mb-0 text-white">
              The GPC browser allows you to browse all components (Segment, Family, Class, Brick and Attribute) of the current GPC schema.
            </p>
            <p class="fs-6 mb-0 mt-2">
              <a href="#FindOutMore" class="fs-6 fst-italic text-white">Access full GPC Standard files</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="gs1-background-color-ui-1 gs1-navigator-spacer pt-3">
      <browser
        @onLanguageChange="handleLanguageChange"
        @onPublicationChange="handlePublicationChange"
        class="p-3 bg-white border border-1 rounded"
      ></browser>
    </section>
    <section class="gs1-background-color-ui-1 gs1-navigator-spacer pt-0">
      <BrowserDownload class="g-0" :selectedLanguage="selectedLanguage" :selectedPublication="selectedPublication"></BrowserDownload>
    </section>
    <section class="gs1-background-color-blue">
      <div class="container gs1-navigator-spacer">
        <div class="row">
          <div class="col-12">
            <h2 class="gs1-h2 mt-0 mb-2 text-white">Any questions?</h2>
            <p class="fs-6 text-white mb-4">We can help you to get started with GPC.</p>
            <GS1LinkButton
              @click="trackEventBeforeRedirect('Contact your local GS1 office')"
              link="https://www.gs1.org/contact"
              class="orange"
              text="Contact your local GS1 office"
            ></GS1LinkButton>
          </div>
        </div>
      </div>
    </section>
    <section class="gs1-background-color-ui-1">
      <div class="container">
        <div class="row gs1-navigator-spacer" id="FindOutMore">
          <h3 class="gs1-h3 mb-4 mt-0">Find out more</h3>
          <div class="col-lg-6">
            <div class="d-flex flex-column">
              <a :class="{ 'pe-none text-muted text-decoration-none fst-italic': downloadState.isDownloadingGpcZip }" class="mb-3" @click="downloadGpcAsZip" href="#" rel="noopener"
                >{{ downloadState.isDownloadingGpcZip ? "Downloading GPC package (.zip)..." : "Current GPC standard full package (.zip)" }}</a
              >
              <a :class="{ 'pe-none text-muted text-decoration-none fst-italic': downloadState.isDownloadingGdsnZip }" class="mb-3" @click="downloadGdsnAsZip" href="#" rel="noopener"
                >{{ downloadState.isDownloadingGdsnZip ? "Downloading GDSN GPC package (.zip)..." : "Current GDSN GPC version full package (.zip)" }}</a
              >
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('Archive versions of the GPC standard')"
                href="https://ref.gs1.org/standards/gpc/archive"
                target="_self"
                >Archive versions of the GPC standard</a
              >
            </div>
          </div>
          <div class="col-lg-6">
            <div class="d-flex flex-column">
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('How GPC works')"
                href="https://www.gs1.org/standards/gpc/how-gpc-works"
                >How GPC works</a
              >
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('GPC Browser Guide')"
                href="https://www.gs1.org/sites/gs1/files/docs/gpc/GPC-Browser-Guide.pdf"
                >GPC Browser Guide</a
              >
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('GPC Browser Service User Guide video')"
                href="https://www.youtube.com/watch?v=0ef1PX7RRrA"
                target="_"
                >GPC Browser Service User Guide Video</a
              >
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('GPC Development &amp; Implementation Guide')"
                href="https://gs1go2.azureedge.net/cdn/ff/sPhCuyCzpmueHnVAaVIFlQZPvTo6dJcfxi02DSCaVg8/1675886575/public/inline-files/202301-gs1-gpc-development-and-implementation-guideline.pdf"
                >GPC Development &amp; Implementation Guide</a
              >
              <a
                class="mb-3"
                @click="trackEventBeforeRedirect('FAQs on Global Product Classification')"
                href="https://support.gs1.org/support/solutions/folders/43000594988"
                >FAQs on Global Product Classification</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Browser from "../components/browser/Browser.vue";
import BrowserDownload from "../components/browser/BrowserDownload.vue";
import GS1LinkButton from "../components/shared/GS1LinkButton.vue";
import { ref } from "@vue/reactivity";
import useGtm from "../composables/useGtm";
import usePublication from "../composables/usePublication";
import fileService from "../services/fileService";

export default {
  components: { Browser, GS1LinkButton, BrowserDownload },
  setup() {
    const { downloadPublicationAsZipAsync } = usePublication();
    const { trackEvent } = useGtm();

    const downloadState = ref({
      isDownloadingGpcZip: false,
      isDownloadingGdsnZip: false,
    });

    const selectedLanguage = ref({});
    const selectedPublication = ref(null);

    const handleLanguageChange = (language) => {
      selectedLanguage.value = language;
    };
    const handlePublicationChange = (publication) => {
      selectedPublication.value = publication;
    };

    const trackEventBeforeRedirect = (title) => {
      trackEvent("gpc_link_click", {
        title: title,
      });
    };

    const downloadZipPackage = async (standard) => {
      event.preventDefault();
      let data = await downloadPublicationAsZipAsync(standard);
      fileService.downloadZip(data.name, data.file);
      return data;
    };

    const downloadGpcAsZip = async () => {
      if (downloadState.value.isDownloadingGpcZip) {
        return;
      }
      downloadState.value.isDownloadingGpcZip = true;
      const response = await downloadZipPackage("gpc");
      if (response.isSuccess) {
        trackEventBeforeRedirect("Current GPC standard full package (.zip)");
      }
      downloadState.value.isDownloadingGpcZip = false;
    };

    const downloadGdsnAsZip = async () => {
      if (downloadState.value.isDownloadingGdsnZip) {
        return;
      }
      downloadState.value.isDownloadingGdsnZip = true;
      const response = await downloadZipPackage("gdsn");
      if (response.isSuccess) {
        trackEventBeforeRedirect("Current GDSN GPC version full package (.zip)");
      }
      downloadState.value.isDownloadingGdsnZip = false;
    };

    return {
      downloadState,
      selectedLanguage,
      selectedPublication,
      handleLanguageChange,
      handlePublicationChange,
      trackEventBeforeRedirect,
      downloadGpcAsZip,
      downloadGdsnAsZip,
    };
  },
};
</script>

<style scoped>
.spinner-download {
  height: 1.25rem;
  width: 1.25rem;
}
</style>
