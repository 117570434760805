import { computed, ref } from "vue";
import gpcBrowserRepository from "../repositories/gpcBrowserRepository";

const _publications = ref([]);
export default function usePublication() {
  const _isFetchingPublication = ref(false);

  async function fetchPublicationsAsync(languageId) {
    _isFetchingPublication.value = true;
    _publications.value = [];
    const response = await gpcBrowserRepository.getPublicationsAsync(languageId);
    _publications.value = response.result;
    _isFetchingPublication.value = false;
    return response;
  }

  async function getPreviousPublicationAsync(publicationId) {
    return gpcBrowserRepository.getPreviousPublicationAsync(publicationId);
  }
  async function isLatestPublicationAsync(publicationId) {
    return gpcBrowserRepository.isLatestPublicationAsync(publicationId);
  }
  async function downloadPublicationAsJsonAsync(id, isStaticFile) {
    return gpcBrowserRepository.exportAsJsonAsync(id, isStaticFile);
  }
  async function downloadPublicationAsXmlAsync(id, isStaticFile) {
    return gpcBrowserRepository.exportAsXmlAsync(id, isStaticFile);
  }
  async function downloadPublicationAsXlsxAsync(id, isStaticFile) {
    return gpcBrowserRepository.exportAsXlsxAsync(id, isStaticFile);
  }
  async function downloadPublicationAsZipAsync(standard) {
    return gpcBrowserRepository.exportAsZipAsync(standard);
  }
  async function downloadDeltaAsJsonAsync(languageId, publicationId = null) {
    return gpcBrowserRepository.exportDeltaAsJsonAsync(languageId, publicationId);
  }
  async function downloadDeltaAsXlsxAsync(languageId, publicationId = null) {
    return gpcBrowserRepository.exportDeltaAsXlsxAsync(languageId, publicationId);
  }
  function getDownloadPublicationReleaseNoteAttachmentUrl(publicationId) {
    return gpcBrowserRepository.getDownloadPublicationReleaseNoteAttachmentUrl(publicationId);
  }

  return {
    downloadPublicationAsJsonAsync,
    downloadPublicationAsXmlAsync,
    downloadPublicationAsXlsxAsync,
    downloadPublicationAsZipAsync,
    getPreviousPublicationAsync,
    isLatestPublicationAsync,
    downloadDeltaAsJsonAsync,
    downloadDeltaAsXlsxAsync,
    fetchPublicationsAsync,
    getDownloadPublicationReleaseNoteAttachmentUrl,
    publications: computed(() => _publications.value),
    isFetchingPublicaiton: computed(() => _isFetchingPublication.value),
  };
}
