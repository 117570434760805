<template>
  <header>
    <nav class="gs1-navbar navbar navbar-expand-lg navbar-light bg-white">
      <div class="container position-relative">
        <a class="navbar-brand" href="https://www.gs1.org"><img width="72" height="59" border="0" alt="GS1 logo" :src="image" /></a>
        <h5 v-if="environment != null" class="fw-bold mt-2 ms-2">{{ environment }}</h5>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="icon-bar top-bar"></span><span class="icon-bar middle-bar"></span><span class="icon-bar bottom-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a aria-current="page" href="https://www.gs1.org/about" class="nav-link">About</a>
              <ul class="gs1-submenu">
                <li class="row m-0">
                  <div class="col-md-4 gs1-submenu-left-side gs1-background-color-ui-1">
                    <h4>About us</h4>
                    <ul class="gs1-submenu-links">
                      <li><GS1Link link="https://www.gs1.org/about/what-we-do" class="gs1-color-blue" text="What we do"></GS1Link></li>
                      <li><GS1Link link="https://www.gs1.org/about/how-we-got-here" class="gs1-color-blue" text="History"></GS1Link></li>
                      <li><GS1Link link="https://www.gs1.org/public-policy" class="gs1-color-blue" text="Public Policy"></GS1Link></li>
                      <li><GS1Link link="https://www.gs1.org/contact" class="gs1-color-blue" text="Contact"></GS1Link></li>
                    </ul>
                  </div>
                  <div class="col-md-8">
                    <div class="row gs1-submenu-right-side">
                      <div class="col-md-12">
                        <h2 class="gs1-submenu-title">
                          <a href="https://www.gs1.org/about">About GS1</a>
                        </h2>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>GS1 Annual Report</h4>
                        <p>Read our latest annual report and archives</p>
                        <a href="https://www.gs1.org/about/annual-report" title="Read our annual report">Read our annual report</a>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>Careers</h4>
                        <p>Explore opportunities and take your career to the next level</p>
                        <a href="https://www.gs1.org/careers" target="_blank" rel="noopener" title="View open positions">View open positions</a>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>Management Board</h4>
                        <p>Get to know the industry leaders who are making a difference</p>
                        <a href="https://www.gs1.org/about/management-board" title="Management Board">Meet the board </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a aria-current="page" href="https://www.gs1.org/standards" class="nav-link">Standards</a>
              <ul class="gs1-submenu">
                <li class="row m-0">
                  <div class="col-md-4 gs1-submenu-left-side gs1-background-color-ui-1">
                    <h4>What do you need?</h4>
                    <ul class="gs1-submenu-links">
                      <li>
                        <GS1Link link="https://www.gs1.org/how-gs1-standards-work" class="gs1-color-blue" text="How GS1 standards work"></GS1Link>
                      </li>
                      <li>
                        <GS1Link link="https://www.gs1.org/standards-development" class="gs1-color-blue" text="GS1 Standards Development"></GS1Link>
                      </li>
                      <li><GS1Link link="https://www.gs1.org/standards/log" class="gs1-color-blue" text="Recently published standards"></GS1Link></li>
                      <li><GS1Link link="https://www.gs1.org/standards/traceability" class="gs1-color-blue" text="Traceability"></GS1Link></li>
                    </ul>
                  </div>
                  <div class="col-md-8">
                    <div class="row gs1-submenu-right-side">
                      <div class="col-md-12">
                        <h2 class="gs1-submenu-title">
                          <a href="https://www.gs1.org/standards">Standards</a>
                        </h2>
                      </div>
                      <div class="col-md-4 gs1-submenu-column align-items-center">
                        <a class="mb-3" href="https://www.gs1.org/standards#identify"
                          ><img alt="identify" src="https://www.gs1.org/sites/default/files/images/identity.png" title="identify"
                        /></a>
                        <h4>Identify</h4>
                        <a href="https://www.gs1.org/id-keys" title="ID Keys">ID Keys</a>
                      </div>
                      <div class="col-md-4 gs1-submenu-column align-items-center">
                        <a class="mb-3" href="https://www.gs1.org/standards#capture"
                          ><img alt="capture" src="https://www.gs1.org/sites/default/files/images/capture.png" title="capture"
                        /></a>
                        <h4>Capture</h4>
                        <a href="https://www.gs1.org/barcodes" title="Barcodes">Barcodes</a>
                        <a href="https://www.gs1.org/epc-rfid" title="EPC/RFID">EPC/RFID</a>
                      </div>
                      <div class="col-md-4 mb-3 gs1-submenu-column align-items-center">
                        <a class="mb-3" href="https://www.gs1.org/standards#share"
                          ><img alt="share" src="https://www.gs1.org/sites/default/files/images/share.jpg" title="Share"
                        /></a>
                        <h4>Share</h4>
                        <a href="https://www.gs1.org/standards/gs1-global-data-model" title="View GS1 Global Data Model Standards"
                          >GS1 Global Data Model</a
                        >
                        <a href="https://www.gs1.org/gdsn-standards" title="View GDSN Standards">GDSN</a>
                        <a href="https://www.gs1.org/standards#shares" title="View all Standards">See all</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a aria-current="page" href="https://www.gs1.org/services" class="nav-link">Services</a>
              <ul class="gs1-submenu">
                <li class="row m-0">
                  <div class="col-md-4 gs1-submenu-left-side gs1-background-color-ui-1">
                    <h4>Useful Links</h4>
                    <ul class="gs1-submenu-links">
                      <li>
                        <GS1Link
                          link="https://www.gs1.org/services/check-digit-calculator"
                          class="gs1-color-blue"
                          text="Check digit calculator"
                        ></GS1Link>
                      </li>
                      <li>
                        <GS1Link link="https://gepir.gs1.org/index.php/search-by-gtin" class="gs1-color-blue" text="Who owns this barcode?"></GS1Link>
                      </li>
                      <li><GS1Link link="https://www.gs1.org/services/gmn-generator" class="gs1-color-blue" text="GMN generator"></GS1Link></li>
                      <li><GS1Link link="https://www.gs1.org/services" class="gs1-color-blue" text="Other services &amp; tools"></GS1Link></li>
                    </ul>
                  </div>
                  <div class="col-md-8">
                    <div class="row gs1-submenu-right-side">
                      <div class="col-md-12">
                        <h2 class="gs1-submenu-title">
                          <a href="https://www.gs1.org/services">Services</a>
                        </h2>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>Global Data Synchronisation Network (GDSN)</h4>
                        <p>The world's largest product data network.</p>
                        <a href="https://www.gs1.org/services/gdsn" title="Global Data Synchronisation Network (GDSN)">Read more</a>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>Verified by GS1</h4>
                        <p>Get product identity right with Verified by GS1</p>
                        <a href="https://www.gs1.org/services/verified-by-gs1" title="Verified by GS1">Read more</a>
                      </div>
                      <div class="col-md-4 gs1-submenu-column">
                        <h4>GPC Browser service</h4>
                        <p>Browse all components of the published GPC schemas.</p>
                        <a href="https://www.gs1.org/services/gpc-browser" title="GPC Browser service">Read more</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a aria-current="page" href="https://www.gs1.org/industries" class="nav-link">Industries</a>
              <ul class="gs1-submenu">
                <li class="row m-0 gs1-submenu-middle">
                  <div class="col-md-12">
                    <h2 class="gs1-submenu-title">
                      <a href="https://www.gs1.org/industries">Industries</a>
                    </h2>
                  </div>
                  <div class="col-md-3 gs1-submenu-column align-items-center">
                    <a class="mb-3" href="https://www.gs1.org/retail"
                      ><img alt="identify" src="https://www.gs1.org/sites/default/files/images/retail.png" title="Retail"
                    /></a>
                    <h4><a class="gs1-h-link" href="https://www.gs1.org/retail" title="Retail">Retail</a></h4>
                  </div>
                  <div class="col-md-3 gs1-submenu-column align-items-center">
                    <a class="mb-3" href="https://www.gs1.org/healthcare" title="Healthcare"
                      ><img alt="Healthcare" src="https://www.gs1.org/sites/default/files/images/healthcare.png" title="Healthcare" />
                    </a>
                    <h4><a class="gs1-h-link" href="https://www.gs1.org/healthcare" title="Healthcare">Healthcare</a></h4>
                  </div>
                  <div class="col-md-3 gs1-submenu-column align-items-center">
                    <a class="mb-3" href="https://www.gs1.org/transport-and-logistics" title="Transport &amp; Logistics"
                      ><img
                        alt="identify"
                        src="https://www.gs1.org/sites/default/files/images/transport-logistics.png"
                        title="Transport &amp; Logistics"
                      />
                    </a>
                    <h4>
                      <a class="gs1-h-link" href="https://www.gs1.org/transport-and-logistics" title="Transport &amp; Logistics"
                        >Transport &amp; Logistics</a
                      >
                    </h4>
                  </div>
                  <div class="col-md-3 gs1-submenu-column align-items-center">
                    <a class="mb-3" href="https://www.gs1.org/technical-industries" title="Technical Industries"
                      ><img
                        alt="Technical Industries"
                        src="https://www.gs1.org/sites/default/files/gs1_icon_technical_industries_rgb_2015-11-10_copy.png"
                        title="Technical Industries"
                      />
                    </a>
                    <h4>
                      <a class="gs1-h-link" href="https://www.gs1.org/technical-industries" title="Technical industries">Technical industries</a>
                    </h4>
                  </div>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a aria-current="page" href="https://www.gs1.org/news-events" class="nav-link">News &amp; Events</a>
              <ul class="gs1-submenu">
                <li class="row m-0 gs1-submenu-middle">
                  <div class="col-md-12">
                    <h2 class="gs1-submenu-title">
                      <a href="https://www.gs1.org/news-events">News &amp; Events</a>
                    </h2>
                  </div>
                  <div class="col-md-3 gs1-submenu-column">
                    <a class="mb-3" href="https://www.gs1.org/events-calendar" title="Events &amp; Calendar">Events &amp; Calendar</a>
                    <a href="https://www.gs1.org/news-archive" title="Latest News">Latest News</a>
                  </div>
                  <div class="col-md-3 gs1-submenu-column">
                    <div class="gs1-event-title">GS1 Global Forum 2025</div>
                    <h4 class="gs1-color-ui-8">
                      <div>
                        <time datetime="2025-02-17T12:00:00Z" class="datetime">17</time> -
                        <time datetime="2025-02-20T12:00:00Z" class="datetime">20 Feb 2025</time> &nbsp;|&nbsp;
                      </div>
                      <div>Noordwijk - Amsterdam</div>
                    </h4>
                    <a href="https://forum.gs1.org/" target="_blank" rel="noopener">View event</a>
                  </div>
                  <div class="col-md-3 gs1-submenu-column">
                    <div class="gs1-event-title">GS1 General Assembly 2025</div>
                    <h4 class="gs1-color-ui-8">
                      <div>
                        <time datetime="2025-05-19T12:00:00Z" class="datetime">19</time> -
                        <time datetime="2025-05-23T12:00:00Z" class="datetime">23 May 2025</time> &nbsp;|&nbsp;
                      </div>
                      <div>Mumbai</div>
                    </h4>
                    <a href="https://ga.gs1.org/" target="_blank" rel="noopener">View event</a>
                  </div>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="btn gs1-button orange" href="https://www.gs1.org/standards/need-gs1-barcode">Get your barcodes</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import image from "../assets/logo.png";
import appSettings from "../configuration/appSettings";
import GS1Link from "./shared/GS1Link.vue";

export default {
  components: { GS1Link },
  setup() {
    const environment = ["Development", "Staging"].includes(appSettings.ENVIRONMENT_NAME) ? appSettings.ENVIRONMENT_NAME : null;
    return { image, environment };
  },
};
</script>

<style scoped>
header {
  box-shadow: 0 2px 3px 0 rgb(0 44 108 / 20%);
}
.nav-link {
  font-size: 0.9375rem;
  color: var(--gs1-color-blue) !important;
  font-weight: 500;
  position: relative;
}
.nav-link:hover {
  background-color: var(--gs1-color-ui-2);
  border-radius: 4px;
}
.nav-link.router-link-active {
  color: #cd3c0d !important;
  background-color: transparent;
  text-decoration: none;
}
.nav-link.router-link-active::before {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  width: 80%;
  height: 4px;
  margin-right: auto;
  margin-left: auto;
  content: "";
  background-color: #cd3c0d;
  border-radius: 100px;
}
@media (max-width: 991.98px) {
  .nav-link.router-link-active {
    color: var(--gs1-color-blue) !important;
    font-size: 1rem;
  }
  .nav-link.router-link-active::before {
    content: none;
  }
}
.navbar-toggler {
  border: none;
  background: transparent !important;
  -webkit-appearance: none;
  border-radius: 0;
  margin-top: 25px;
  margin-left: 22px;
  margin-right: 0;
  margin-bottom: 25px;
  background-color: transparent;
}

.navbar-toggler:hover {
  background: transparent !important;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .icon-bar {
  transition: all 0.2s;
  width: 30px;
  height: 3px;
  display: block;
  margin-bottom: 5px;
  background-color: #002c6c;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .icon-bar {
  background-color: #002c6c;
}

.gs1-navbar {
  height: 106px;
}
.gs1-navbar .nav-item {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
}
.gs1-navbar a {
  font-size: 0.875rem;
}

.nav-item:hover > .gs1-submenu {
  display: block !important;
}
.gs1-submenu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 108px;
  z-index: 3;
  background-color: var(--gs1-color-white);
  border: 1px solid var(--gs1-color-ui-2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 0;
  min-height: 250px;
}
.gs1-submenu-left-side {
  border-bottom-left-radius: 4px;
  padding-top: 24px;
  padding-left: 36px;
  min-height: 250px;
}
.gs1-submenu-left-side > h4 {
  color: var(--gs1-color-ui-8);
  font-size: 1rem;
  margin-bottom: 26px;
  margin-top: 0px;
  font-weight: 300;
  line-height: 1.2;
}
.gs1-submenu-right-side {
  border-bottom-right-radius: 4px;
  padding-left: 45px;
  padding-top: 24px;
  padding-right: 45px;
}
.gs1-submenu-middle {
  border-bottom-right-radius: 4px;
  padding-left: 40px;
  padding-top: 24px;
  padding-right: 40px;
  padding-bottom: 45px;
}
.gs1-submenu-links {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
}
.gs1-submenu-title {
  line-height: 1rem;
  margin-top: 0;
}
.gs1-submenu-title::after {
  content: "";
  display: block;
  width: calc(100% - 15px);
  height: 1px;
  background-color: var(--gs1-color-ui-3);
  margin-top: 7px;
}
.gs1-submenu-title > a {
  color: var(--gs1-color-blue);
  font-size: 1rem;
  font-weight: 500;
  padding: 0;
  text-decoration: none;
}
.gs1-submenu-column {
  display: flex;
  flex-direction: column;
}
.gs1-submenu-column > h4 {
  color: var(--gs1-color-blue);
  font-size: 1rem;
  margin-top: 0;
  font-weight: 300;
  line-height: 1.2;
}
.gs1-submenu-column > p {
  flex: 1;
  color: var(--gs1-color-ui-8);
  font-size: 0.9375rem;
}
.gs1-submenu-column > a {
  font-weight: 500;
  padding: 0;
  color: var(--gs1-color-link-accessible);
  font-size: 0.875rem;
  text-decoration: none;
}
.gs1-h-link {
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 25px;
  padding: 0;
  text-decoration: none;
  color: var(--gs1-color-blue);
}
.gs1-h-link:hover {
  text-decoration: underline !important;
}
.gs1-event-title {
  color: var(--gs1-color-blue);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
